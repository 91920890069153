<template>
  <Layout>
    <div class="container-fluid pt-3">
      <div class="row mb-3">
        <div class="col-4">
          <h5 class="page-header font-display fs-4">New Brief</h5>
        </div>
        <div class="col-8 text-end">
          <a class="btn btn-success text-center" href="#" role="button" @click="$router.push({ name: 'home' })"
            >View calendar</a
          >
        </div>
      </div>
      <hr class="dotted-spacer" />

      <!-- Loading spinner -->
      <div
        v-if="$apollo.loading"
        class="position-fixed end-0 w-100 justify-content-center"
        style="background: rgba(255, 255, 255, 0.8); height: 100vh; z-index: 1050"
      >
        <div class="text-center col pt-5 mt-5">
          <BaseSpinner />
          <p class="d-block lead fs-4 mt-5">Loading brief data</p>
        </div>
      </div>
      <div v-else class="row pt-4 justify-content-around">
        <BaseAlert v-if="showAlert" class="mb-4" :type="alert.type">
          <span slot="title">{{ alert.title }}</span>
          <ul v-if="typeof alert.message === 'object'" slot="message">
            <li v-for="m in alert.message" :key="m">{{ m }}</li>
          </ul>
          <span v-else slot="message">{{ alert.message }}</span>
        </BaseAlert>
        <div class="col">
          <ValidationObserver ref="form" v-slot="{}">
            <form @submit.prevent="createBrief">
              <!-- Activities -->
              <div v-for="(activity, index) in brief.activities" :key="'activity-' + index" class="row">
                <div class="col-xl-2 col-lg-6">
                  <BaseFormGroup :size="'md'" :label="'Dates: '">
                    <BaseDatepicker v-model="activity.dates" type="text" name="dates" />
                  </BaseFormGroup>
                </div>
                <div class="col-xl-3 col-lg-6">
                  <BaseFormGroup :size="'md'" :label="'Locations:'">
                    <BaseMultiselect
                      v-model="activity.locations"
                      :multiple="true"
                      :taggable="false"
                      :options="locations"
                      track-by="name"
                      name="locations"
                    />
                  </BaseFormGroup>
                </div>
                <div class="col-xl-3 col-lg-6">
                  <BaseFormGroup :size="'md'" :label="'Channel:'">
                    <BaseMultiselect
                      v-model="activity.channel"
                      :multiple="false"
                      :taggable="false"
                      :options="channels"
                      track-by="name"
                      name="channels"
                    />
                  </BaseFormGroup>
                </div>
                <div class="col-xl-3 col-lg-5">
                  <BaseFormGroup :size="'md'" :label="'Spokespersons:'">
                    <BaseMultiselect
                      v-model="activity.spokespersons"
                      :multiple="true"
                      :taggable="false"
                      :options="spokespersons"
                      track-by="name"
                      name="spokespersons"
                    />
                  </BaseFormGroup>
                </div>
                <div
                  v-if="index == brief.activities.length - 1 && brief.activities.length > 1"
                  class="col-xl-1 col-lg-1"
                >
                  <div class="text-end my-3">
                    <a class="link-danger fs-4" @click="removeActivity(index)"><BaseIcon :name="['fas', 'times']" /></a>
                  </div>
                </div>
              </div>
              <div class="row mt-2 plusrow">
                <div class="col-1 offset-11 text-start text-primary">
                  <a class="link-primary fs-5" @click="addActivity">Add activity</a>
                </div>
              </div>

              <!-- Brief -->
              <div class="row mt-4">
                <div class="col">
                  <BaseFormGroup :size="'lg'" :label="'Subject (max 80 characters):'">
                    <ValidationProvider v-slot="{ errors }" name="subject" rules="required">
                      <BaseInput
                        v-model="brief.title"
                        v-tooltip.top-center="'This will be displayed alongside the location and channel on the calendar'"
                        type="text"
                        name="subject"
                        placeholder="Subject"
                        maxlength="80"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </BaseFormGroup>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <BaseFormGroup :size="'lg'" :label="'Description:'">
                    <ValidationProvider v-slot="{ errors }" name="description" rules="required">
                      <BaseInput
                        v-model="brief.proposedDetails"
                        type="textarea"
                        name="description"
                        placeholder="Description"
                        :rows="5"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </BaseFormGroup>
                </div>
              </div>
              <div class="row mt-4 justify-content-between">
                <div class="col-xl-6 col-lg-8">
                  <BaseFormGroup :size="'md'" :label="'Key contact:'">
                    <ValidationProvider v-slot="{ errors }" name="owner" rules="required">
                      <BaseMultiselect
                        v-model="brief.owner"
                        :multiple="false"
                        :taggable="false"
                        :options="teamMembers"
                        track-by="name"
                        name="owner"
                        :disabled="false"
                        :error="errors[0]"
                        :allow-empty="false"
                      />
                    </ValidationProvider>
                  </BaseFormGroup>
                </div>
                <div class="col-xl-4 col-lg-4">
                  <BaseFormGroup :size="'sm'" :label="'Confidential:'">
                    <BaseInput v-model="brief.confidential" type="checkbox" name="confidential"></BaseInput>
                  </BaseFormGroup>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <BaseFormGroup :size="'lg'" :label="'Team:'">
                    <ValidationProvider v-slot="{ errors }" name="team">
                      <BaseMultiselect
                        v-model="brief.team"
                        :multiple="true"
                        :taggable="false"
                        :options="teamMembers"
                        track-by="name"
                        name="asignees"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </BaseFormGroup>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <BaseFormGroup :size="'lg'" :label="'Tags:'">
                    <ValidationProvider v-slot="{ errors }" name="tags">
                      <BaseMultiselect
                        v-model="brief.tags"
                        :multiple="true"
                        :taggable="false"
                        :options="tags"
                        track-by="name"
                        name="tags"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </BaseFormGroup>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col text-end">
                  <BaseButton type="submit" :disabled="isLoading" :classes="'btn-success btn-lg'">
                    <span>Submit</span>
                  </BaseButton>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@layouts/main";
import { LocalGetSelf } from "@gql/user";
import { GetTags, GetPeople, GetChannelsForActivity } from "@gql/tag";
import { CreateBrief, GetBrief } from "@gql/brief";
import { GetActivityStatuses } from "@gql/activityStatus";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { parseISO, format } from "date-fns";
import { orderBy, uniq } from "lodash";

export default {
  page() {
    return {
      title: "New Brief",
      meta: [
        {
          name: "description",
          content: `Create brief.`,
        },
      ],
    };
  },
  components: { Layout, ValidationProvider, ValidationObserver },
  props: {
    cloneFromBriefId: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      alert: {},
      showAlert: false,
      baseActivity: {
        dates: {
          startDate: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
          endDate: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
        },
        locations: [],
        channel: null,
        spokespersons: [],
      },
      brief: {
        title: "",
        text: "",
        owner: null,
        team: [],
        tags: [],
        confidential: false,
        activities: [
          {
            dates: {
              startDate: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
              endDate: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
            },
            locations: [],
            channel: null,
            spokespersons: [],
          },
        ],
      },
    };
  },
  apollo: {
    user: {
      query: LocalGetSelf,
      async result(data) {
        this.$log.debug(data.data.user);
        const result = await this.$apollo.query({
          query: GetTags,
          variables: {
            where: {
              company: {
                id: { equals: data.data.user.company.id },
              },
              person: { is: { id: { equals: data.data.user.id } } },
            },
          },
        });
        this.$log.debug("Got person tag - ", result);
        this.brief.owner = result.data.tags[0];
      },
    },
    tags: {
      query: GetTags,
      variables() {
        return {
          where: {
            company: {
              id: { equals: this.user.company.id },
            },
            type: { name: { equals: "generic" } },
          },
        };
      },
      skip() {
        return !this.user;
      },
      update(data) {
        this.$log.debug("Got Tags from API: ", data);
        return orderBy(
          data.tags.map((tag) => ({
            ...tag,
            color: tag.color ? tag.color : tag.type.color,
          })),
          ["label"]
        );
      },
    },
    spokespersons: {
      query: GetPeople,
      variables() {
        return {
          companyId: this.user.company.id,
          category: "spokesperson",
        };
      },
      skip() {
        return !this.user;
      },
      update(data) {
        this.$log.debug("Got Spokespersons from API: ", data);
        return orderBy(
          data.tags.map((tag) => ({
            ...tag,
            color: tag.color ? tag.color : tag.type.color,
          })),
          ["label"]
        );
      },
    },
    locations: {
      query: GetTags,
      variables() {
        return {
          where: {
            company: {
              id: { equals: this.user.company.id },
            },
            type: { name: { equals: "location" } },
          },
        };
      },
      skip() {
        return !this.user;
      },
      update(data) {
        this.$log.debug("Got Locations from API: ", data);
        return orderBy(
          data.tags.map((tag) => ({
            ...tag,
            color: tag.color ? tag.color : tag.type.color,
          })),
          ["label"]
        );
      },
    },
    groups: {
      query: GetTags,
      variables() {
        return {
          where: {
            company: {
              id: { equals: this.user.company.id },
            },
            type: { name: { equals: "group" } },
          },
        };
      },
      update(data) {
        this.$log.debug("Got Groups from API: ", data);
        return orderBy(
          data.tags.map((tag) => ({
            ...tag,
            color: tag.color ? tag.color : tag.type.color,
          })),
          ["label"]
        );
      },
    },
    teamMembers: {
      query: GetPeople,
      variables() {
        return {
          companyId: this.user.company.id,
        };
      },
      skip() {
        return !this.user;
      },
      update(data) {
        this.$log.debug("Got Team Members from API: ", data);
        return orderBy(
          data.tags.map((tag) => ({
            ...tag,
            color: tag.color ? tag.color : tag.type.color,
          })),
          ["label"]
        );
      },
    },
    channels: {
      query: GetChannelsForActivity,
      variables() {
        return {
          companyId: this.user.company.id,
          orderBy: { label: "asc" },
        };
      },
      update: ({ tags }) => orderBy(tags, ["label"]),
      error(error) {
        this.$log.error("There was an error", error);
      },
      skip() {
        return !this.user;
      },
    },
    statuses: {
      query: GetActivityStatuses,
      variables() {
        return {
          companyId: this.user.company.id,
        };
      },
      update: ({ activityStatuses }) =>
        orderBy(
          activityStatuses.filter((status) => status.label !== "Ready for approval"),
          ["label"]
        ),
      error(error) {
        this.$log.error("There was an error", error);
      },
      skip() {
        return !this.user;
      },
    },
    clonedBrief: {
      query: GetBrief,
      variables() {
        this.$log.warn(`Cloning from: ${this.cloneFromBriefId}`);
        return {
          id: this.cloneFromBriefId,
        };
      },
      skip() {
        return !this.cloneFromBriefId
      },
      update(data) {
        if (!data.brief) {
          this.$router.push({ name: "404", params: { resource: "Brief" } });
        }
        if (data.brief.isConfidential && !this.user.isAdminUser) {
          this.$router.push({ name: "home" });
          return;
        }

        data.brief.team = data.brief.team
          ? data.brief.team.map((tag) => ({ ...tag, color: tag.color ? tag.color : tag.type.color }))
          : [];
        data.brief.tags = data.brief.tags.map((tag) => ({ ...tag, color: tag.color ? tag.color : tag.type.color }));
        data.brief.activities = data.brief.activities.map((activity) => ({
          ...activity,
          dates: {
            startDate: activity.startDate,
            endDate: activity.endDate,
          },
          spokespersons: activity.spokespersons.length > 0
            ? activity.spokespersons.map(tag => ({
                ...tag,
                color: tag.color ? tag.color : tag.type.color,
              }))
            : [],
          location: activity.locations.length > 0
            ? activity.locations.map(tag => ({
                ...tag,
                color: tag.color ? tag.color : tag.type.color,
              }))
            : [],
        }));

        return data.brief;
      },
      result(result) {
        // We actually need to mutate the data for brief in the base object.
        if (!result.data.brief) {
          this.$router.push({ name: "404", params: { resource: "Brief" } });
        }
        this.$log.warn("Got Brief from API: ", result.data);
        const data = result.data
        if (result.data.brief.isConfidential && !this.user.isAdminUser) {
          this.$router.push({ name: "home" });
          return;
        }

        data.brief.team = data.brief.team
          ? data.brief.team.map((tag) => ({ ...tag, color: tag.color ? tag.color : tag.type.color }))
          : [];
        data.brief.tags = data.brief.tags.map((tag) => ({ ...tag, color: tag.color ? tag.color : tag.type.color }));
        data.brief.activities = data.brief.activities.map((activity) => ({
          ...activity,
          dates: {
            startDate: activity.startDate,
            endDate: activity.endDate,
          },
          spokespersons: activity.spokespersons.length > 0
            ? activity.spokespersons.map(tag => ({
                ...tag,
                color: tag.color ? tag.color : tag.type.color,
              }))
            : [],
          location: activity.locations.length > 0
            ? activity.locations.map(tag => ({
                ...tag,
                color: tag.color ? tag.color : tag.type.color,
              }))
            : [],
        }));

        this.brief = data.brief

        setTimeout(this.checkForBlockingReminders, 1000);
      },
      fetchPolicy: "no-cache",
    }
  },
  methods: {
    async createBrief() {
      this.isLoading = true;
      this.alert = {};
      this.showAlert = false;

      // Validate the form
      const validForm = await this.$refs.form.validate();

      // Manuel validation bc vee validate can't handle multiselects
      this.alert.message = [];
      for (const activity of this.brief.activities) {
        if (activity.locations.length === 0) {
          this.showAlert = true;
          this.alert.type = "error";
          this.alert.title = "Error";
          this.alert.message.push("A location is required for every activity");
        }
        if (!activity.channel) {
          this.showAlert = true;
          this.alert.type = "error";
          this.alert.title = "Error";
          this.alert.message.push("A channel is required for every activity");
        }
      }
      if (this.showAlert || !validForm) {
        this.isLoading = false;
        this.showAlert = true;
        this.alert.type = "error";
        this.alert.title = "Error";
        this.alert.message.push("Please check the form below for errors");
        this.alert.message = uniq(this.alert.message);
        return false;
      }
      // Validation good, reset
      this.$refs.form.reset();

      // Find the right creation status by step down priority
      let creationStatus = this.statuses.find((status) => ((this.user.isAdminUser || this.user.selfApprove) && status.defaultStatusOnCreateGroupOwner))
      creationStatus = creationStatus || this.statuses.find((status) => ((this.user.role.name === "TEAM" && status.defaultStatusOnCreateTeam)))
      creationStatus = creationStatus || this.statuses.find((status) => ((this.user.role.name === "USER" && status.defaultStatusOnCreateStaff)))
      creationStatus = creationStatus || this.statuses.find((status) => (status.defaultStatusOnCreateStaff))

      this.$apollo
        .mutate({
          mutation: CreateBrief,
          variables: {
            data: {
              title: this.brief.title,
              proposedDetails: this.brief.proposedDetails,
              isConfidential: this.brief.isConfidential,
              tags: { connect: this.brief.tags.map((tag) => ({ id: tag.id })) },
              team: { connect: this.brief.team.map((tag) => ({ id: tag.id })) },
              owner: { connect: { id: this.brief.owner.id } },

              createdBy: { connect: { id: this.user.id } },
              company: { connect: { id: this.user.company.id } },

              activities: {
                create: this.brief.activities.map((activity) => ({
                  owner: { connect: { id: this.brief.owner.person.id } },
                  locations: activity.locations.length > 0 ? { connect: activity.locations.map(tag => ({ id: tag.id })) } : undefined,
                  channel: { connect: { id: activity.channel.id } },
                  status: { connect: { id: creationStatus.id } },
                  spokespersons: activity.spokespersons.length > 0 ? { connect: activity.spokespersons.map(tag => ({ id: tag.id })) } : undefined,
                  createdBy: { connect: { id: this.user.id } },
                  company: { connect: { id: this.user.company.id } },
                  startDate: format(parseISO(activity.dates.startDate), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
                  endDate: format(parseISO(activity.dates.endDate), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
                })),
              },
            },
          },
          update: (store, { data: { createBrief } }) => {},
          error(err) {
            this.$log.error(err);
            this.showAlert = true;
            this.alert.type = "error";
            this.alert.title = "There was a problem";
            this.alert.message = err;
            this.isLoading = false;
          },
        })
        .then((data) => {
          this.showAlert = true;
          this.alert.type = "success";
          this.alert.title = "Success";
          this.alert.message = "Brief created";
          this.isLoading = false;
          this.$refs.form.reset();
          // Redirect
          setTimeout(() => {
            this.$router.push({ name: "home" });
          }, 1000);
        })
        .catch((err) => {
          // Error
          this.showAlert = true;
          this.alert.type = "error";
          this.alert.title = "There was a problem";
          this.isLoading = false;
          this.alert.message = err;
        });
    },
    addActivity() {
      this.brief.activities.push({ ...this.baseActivity });
    },
    removeActivity(index) {
      this.$log.debug(index);
      this.brief.activities = this.brief.activities.filter((activity, idx) => idx !== index);
      this.$forceUpdate();
      // this.brief.activities.forEach((activity, idx) => {
      //   if (idx <= index) {
      //     this["activity-" + idx] += 1;
      //   }
      // });
    },
  },
};
</script>
